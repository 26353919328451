import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getDeletedClientGroups, getDeletedClients, restoreClient, restoreClientGroup } from "api/services/clients/clients";
import { getDeletedUsers, restoreUser } from "api/services/users";
import Loader from "components/Loader";
import Table from "components/Table";
import { snack } from "components/toast";
import { useConfirm } from "context/ConfirmDialog";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { exportDeletedClientsGroupPageReport, exportDeletedClientsPageReport } from "api/services/reports";
import SearchContainer from "components/SearchContainer";
import { getCountryCode } from "validations/phoneNumbr";
import { getCurrentFormattedDateTime } from "utils";
import { useUserData } from "context/UserProfile";
import { usePermissions } from "context/PermissionsProvider";
import { Permissions } from "data/permissons";
import PermissionDenied from "components/PemissionDenied";


function DeletedClientGroups(state) {

  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [clientsData, setClientData] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [search, setSearch] = useState('');
  const { data: userData } = useUserData();
  const { permissions } = usePermissions();

  const { data, isLoading }: ResType = useQuery(["deleted-client-groups", {
    limit: pageCount,
    offset: page * pageCount,
    search: search
  }], getDeletedClientGroups, {
    onSuccess: (res) => {
      setClientData(res?.data[0]);
      setTotalCount(res?.data[1]);
    },
    onError: (err: any) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching deleted clients`);
      }
    }
  });

  const { mutate } = useMutation(restoreClientGroup, {
    onSuccess: () => {
      snack.success("Clients Group Access Restored");
      queryClient.invalidateQueries("deleted-client-groups");
    },
    onError: (err: any) => {
      if (err && !err?.error) {
        snack.error(handleError(err));
      } else if (!err?.error) {
        snack.error(`An error occurred while restoring clients`);
      }
    },
  });

  const handleRestore = (id: number) => {
    confirm({
      msg: "Are you sure you want to restore this clients Group ?",
      action: () => mutate(id),
    });
  };

  const { mutate: mutate1 } = useMutation(exportDeletedClientsGroupPageReport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      const formattedDateTime = getCurrentFormattedDateTime();
      link.href = file;
      link.download = `ATOM - Deleted Clients Group ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  const handleExport = () => {
    mutate1({
      ...state,
    });

  }
  const handleSearch = (e) => {
    setSearch(e);
    setPage(0);
  }

  const columns = [
    {
      key: "clientId",
      title: "Client Group ID",
    },
    {
      key: "displayName",
      title: "Display Name",
    },
    {
      key: "mobileNumber",
      title: "Mobile Number",
      render: (row: any) => <Typography>{`+${getCountryCode(row?.countryCode) || 91} ${row?.mobileNumber}`}</Typography>,
    },
    {
      key: "email",
      title: "Email ID",
    },
  ]

  if (userData?.role?.name === "Admin" || permissions.includes(Permissions.RESTORE_DELETED_CLIENT_GROUPS)) {
    columns.push({
      key: "Action",
      title: "Action",
      render: (item: any) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => handleRestore(item?.id)}
          >
            Restore
          </Button>
        );
      },
    })
  }

  if (isLoading) return <Loader />;

  if (userData?.role?.name !== "Admin" && !permissions.includes(Permissions.VIEW_DELETED_CLIENT_GROUPS)) {
    return (
      <PermissionDenied />
    );
  }

  return (
    <Box>
      <Box display="flex" gap={3} justifyContent="space-between" mt={2} mr={2} mb={1}>
        <Typography variant="subtitle1" px={2}>
          Deleted Client Groups
        </Typography>
        <SearchContainer
          debounced
          value={search}
          placeHolder="Search by Display Name | Email"
          onChange={handleSearch}
        />
        <Button variant="outlined" color="secondary" onClick={handleExport}
          startIcon={<FileDownloadOutlinedIcon />}     >
          Export
        </Button>
      </Box>
      <Box px={1}>
        <Table
          sx={{ mb: 0, pb: 0 }}
          loading={isLoading}
          data={clientsData || []}
          columns={columns || []}
          pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
        />
      </Box>
    </Box>
  );
}

export default DeletedClientGroups;
