import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { formatToRupeess } from "utils/formattedAmount";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClientsHeader from "views/atom-pro/ClientsHeader";
import { LinkRouter } from "components/BreadCrumbs";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import { exportIncomeTaxEproceedings, getFyaSections, getIncomeTaxEproceedings } from "api/services/automation/income-tax";
import { clientCategories, getAutFinancialYears, sectionCodeList } from "pages/atom-pro/constants";
import SearchContainer from "components/SearchContainer";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Loader from "components/Loader";
import CategoryIcon from '@mui/icons-material/Category';
import { handleError } from "utils/handleError";
import { getCurrentFormattedDateTime } from "utils";
import { snack } from "components/toast";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';



const EproceedingFya = (tab) => {
  const [active, setActive] = useState("FYA");
  useTitle("ATOM Pro | e-Proceedings");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const [section, setSection] = useState<any>(null);
  const [assessmentYear, setAssessmentYear] = useState<any>(null);
  const [clientCategory, setClientCategory] = useState<any>(null);
  const [sections, setSectionData] = useState<any>(null);
  const params = useParams();
  const location = useLocation();
  const state: any = location.state;

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const { data, isLoading }: ResType = useQuery(
    [
      "e-proceedings",
      {
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        section: section,
        assessmentYear: assessmentYear,
        clientCategory: clientCategory,
      },
    ],
    getIncomeTaxEproceedings,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );


  const { data: sectionData, isLoading: sectionIsloading }: ResType = useQuery(
    [
      "e-proceedings-sections",

    ],
    getFyaSections,
    {
      onSuccess: (res) => {
        setSectionData(res?.data);
      },
    }
  );

  const onChange = (e: any) => {
    setSection(e.target.value);
    setPage(0);

  };

  const onAssessmentYearChange = (e: any) => {
    setAssessmentYear(e.target.value);
    setPage(0);
  };

  const onClientCategoryChange = (e: any) => {
    setClientCategory(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e)
    setPage(0)
  }
  const { mutate: mutate1 } = useMutation(exportIncomeTaxEproceedings, {
    onSuccess: (res: any) => {
        const arr = new Uint8Array(res.data?.data);
        const blob = new Blob([arr], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const file = window.URL.createObjectURL(blob);
        const formattedDateTime = getCurrentFormattedDateTime();

        let link = document.createElement("a");
        link.href = file;
        link.download = `ATOM Pro - Income Tax e-Proceedings (For Your Action) ${formattedDateTime}.xlsx`;
        link.click();
        snack.success("Exported Successfully");
    },
    onError: (err: any) => {
        snack.error(err);
        snack.error(handleError(err));
    },
});
const handleExport = () => {
    mutate1({
        ...state,
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        section: section,
        assessmentYear: assessmentYear,
        clientCategory: clientCategory,
    });
};
  if (isLoading || sectionIsloading) return <Loader />

  return (
    <>
      {/* <Breadcrumbs>
        <LinkRouter underline="hover" color="inherit" to="/atom-pro/income-tax">
          Atom Pro
        </LinkRouter>
        <Typography>e-Proceedings</Typography>
      </Breadcrumbs> */}

      <Box p={1} mt={1}>
        <Box mb={1} display="flex" alignContent="center">

          <TextField
            name="assessmentYear"
            value={assessmentYear}
            onChange={onAssessmentYearChange}
            size="small"
            label="Assessment Year"
            select
            sx={{ width: "150px", marginRight: "20px" }}
          >
            <MenuItem key={""} value={""}>
              Select
            </MenuItem>
            <MenuItem key={""} value={"null"}>
              NA
            </MenuItem>
            {getAutFinancialYears().map((year: string) => (
              <MenuItem key={year} value={year.split("-")[0]}>
                {year}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name="clientCategory"
            value={clientCategory}
            onChange={onClientCategoryChange}
            size="small"
            label="Category"
            select
            sx={{ width: "230px", marginRight: "20px" }}
          >
            <MenuItem key={""} value={""}>
              Select
            </MenuItem>
            {clientCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="section"
            value={section}
            onChange={onChange}
            size="small"
            label="Section"
            select
            sx={{ width: "100px", marginRight: "20px" }}
          >
            <MenuItem key={""} value={""}>
              Select
            </MenuItem>
            <MenuItem key={""} value={"null"}>
              NA
            </MenuItem>
            {sections?.map((option: any, index: any) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>


          <SearchContainer
            debounced
            value={search}
            placeHolder="Search by Client | PAN | Proceeding Name"
            onChange={handleSearch}
          />
          <Button
            sx={{ marginLeft: '10px',marginRight: '10px' }}
            onClick={() => {
              setSection("");
              setAssessmentYear(null);
              setClientCategory(null);
              setSearch("");
            }}
            size="small"
            color="secondary"
            variant="outlined"
          >
            Clear Filters
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleExport}
                    startIcon={<FileDownloadOutlinedIcon />}
                >
                    Export
                </Button>
          <Box>{/* <ClientsHeader search={search} setSearch={setSearch} /> */}</Box>
        </Box>

        <Table
          sx={{ height: "500px" }}
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data.result || []}
          columns={columns}
          loading={isLoading}
        // onRowClick={handleRowClick}
        />
      </Box>
    </>
  );
};

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

function generateRandomColor(index) {
  const colors = ["#ff9595", "#EDE7FB", "#FBE7E9", "#FCF4C5", "#EEFCC9", "#F6F6F6", "#EAFFE7"];
  const colorIndex = index % colors.length;
  return colors[colorIndex];
}

const getCategoryBackgroundColor = (category) => {
  switch (category) {
    case 'individual':
      return '#FCF4C5';
    case 'huf':
      return '#E0F7FA';
    case 'partnership_firm':
      return '#FFE0B2';
    case 'llp':
      return '#D1C4E9';
    case 'company':
      return '#C8E6C9';
    case 'aop':
      return '#FFCCBC';
    case 'boi':
      return '#C5CAE9';
    case 'trust':
      return '#F8BBD0';
    case 'government':
      return '#B2DFDB';
    case 'local_authority':
      return '#FFD54F';
    case 'artificial_judicial_person':
      return '#F0F4C3';
    case 'corporations':
      return '#D7CCC8';
    default:
      return '#FFFFFF';
  }
};



const columns: Array<ColumnType> = [
  {
    key: "assesmentYear",
    title: "Assessment Year",
    render: (v) => (
      <Typography
      // sx={{
      //   backgroundColor: "#000058",
      //   display: "inline-block",
      //   padding: "2px 5px",
      //   border: "1px solid #bb9ce7",
      //   borderRadius: "4px",
      //   color: "#FFFFFF",
      //   fontSize: "12px",
      // }}
      >
        {generateAssessmentYear(v?.assesmentYear)}
      </Typography>
    ),
  },
  {
    key: "",
    title: (
      <Box display="flex" alignItems="center">
        <CategoryIcon style={{ width: "20px", marginRight: "5px" }} />
        Category
      </Box>
    ),
    render: (v) => {
      const categoryLabel = clientCategories.find(category => category.value === v?.client?.category)?.label || '';
      const backgroundColor = getCategoryBackgroundColor(v?.client?.category);

      return (
        <Typography
        // sx={{
        //   backgroundColor: backgroundColor,
        //   display: "inline-block",
        //   padding: "0px 5px",
        //   border: "1px solid #EDE7FB",
        //   borderRadius: "4px",
        //   color: "#222222",
        //   fontSize: "14px",
        // }}
        >
          {categoryLabel}
        </Typography>
      )
    }
  },


  {
    key: "",
    title: (
      <Box display="flex" alignItems="center">
        <PersonOutlineOutlinedIcon style={{ width: "20px", marginRight: "5px" }} />
        client Name (PAN)
      </Box>
    ),
    render: (v) => (
      <Typography
      // sx={{
      //   color: "#222222",
      //   fontSize: "12px",
      // }}
      >
        {v?.client?.displayName} (
        <span
        // style={{
        //   backgroundColor: "#ff9595",
        //   display: "inline-block",
        //   padding: "0px 5px",
        //   border: "1px solid #EDE7FB",
        //   borderRadius: "4px",
        //   color: "#222222",
        //   fontSize: "12px",
        // }}
        >
          {v?.pan}
        </span>
        )
      </Typography>
    ),
  },
 
  {
    key: "noticeName",
    title: (
      <Box display="flex" alignItems="center">
        <FormatListBulletedIcon style={{ width: "20px", marginRight: "5px" }} /> Section
      </Box>
    ),
    render: (v) => (
      <Typography
      // sx={{
      //   color: "#222222",
      // }}
      // variant="body2"
      >
        {v?.noticeName ? (
          <span
          // style={{
          //   backgroundColor: generateRandomColor(v.id),
          //   display: "inline-block",
          //   padding: "0px 5px",
          //   border: "1px solid #EDE7FB",
          //   borderRadius: "4px",
          //   color: "#222222",
          //   fontSize: "12px",
          // }}
          >
            {v?.noticeName}
          </span>
        ) : (
          "--"
        )}
      </Typography>
    ),
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Name",
    render: (v) => (
      <Typography
      // variant="subtitle2"
      // sx={{
      //   display: "inline-block",
      //   padding: "0px 5px",

      //   color: "#222222",
      //   fontSize: "12px",
      // }}
      >
        {v.proceedingName}
      </Typography>
    ),
  },



  {
    key: "",
    title: "Action",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-action/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default EproceedingFya;
