import { Box, Button, IconButton, Typography } from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Visibility } from "@mui/icons-material";
import {
  exportClientproceedingFyi,
  getClientproceedingFyi,
  getIncomeTaxProfile,
} from "api/services/automation/income-tax";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import { resetState } from "redux/reducers/clientsSlice";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getCurrentFormattedDateTime } from "utils";
import { snack } from "components/toast";
import { handleError } from "utils/handleError";

const ClientEproceedingFyi = (state) => {
  useTitle("ATOM Pro | Clients");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(resetState());
    };
  }, []);
  const [checkAccess, setCheckAccess] = useState(true)
  const { data, isLoading, }: ResType = useQuery(
    [
      "client-proceedingfyi",
      params.incometaxid,
      {
        limit: pageCount,
        offset: page * pageCount,
      },
    ],
    getClientproceedingFyi,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
        setCheckAccess(res?.data?.accessDenied)
      },
    }
  );

  const { data: profiledata, isLoading: isprofileloading }: ResType = useQuery(
    ["get-income-tax-profile", params.incometaxid],
    getIncomeTaxProfile,
  );
  const clientName = profiledata?.data?.profileDetails?.name;
  const { mutate: mutate1 } = useMutation(exportClientproceedingFyi, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      const formattedDateTime = getCurrentFormattedDateTime();
      link.href = file;
      link.download = `ATOM Pro - ${clientName} Income Tax e-Proceedings (For Your Information) ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    mutate1({
      incometaxid: params.incometaxid,
      ...state,
    });

  }

  if (isLoading) return <Loader />
  return (
    <>

      {!checkAccess && (
        <AccessDenied />
      )}
      <Box display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
                <Button variant="outlined" color="secondary" onClick={handleExport}
                    startIcon={<FileDownloadOutlinedIcon />}>
                    Export
                </Button>
            </Box>
      {checkAccess && (
        <Box p={2}>
          <Table
            sx={{ height: "480px" }}
            pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
            data={data?.data?.result || []}
            columns={columns}
            loading={isLoading}
          />
        </Box>
      )}

    </>
  );
};

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

const columns: Array<ColumnType> = [
  {
    key: "assessmentYear",
    title: "Assessment Year",
    render: (v) => (
      <Typography
      >
        {generateAssessmentYear(v?.assessmentYear)}
      </Typography>
    ),
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Name",
    render: (v) => (
      <Typography>
        {v.proceedingName}
      </Typography>
    ),
  },
  {
    key: "noticeName",
    title: (
      <Box display="flex" alignItems="center">
        <FormatListBulletedIcon style={{ width: "20px", marginRight: "5px" }} /> Sections
      </Box>
    ),
    render: (v) =>
      v?.noticeName ? (
        <Typography >
          {v?.noticeName}
        </Typography>
      ) : (
        "--"
      ),
  },
  {
    key: "",
    title: "Actions",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-information/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default ClientEproceedingFyi;
