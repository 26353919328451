import {
    Box,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
} from "@mui/material";
import { getSmtp, updateOrganization, updateSmtp } from "api/services/organization";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import PasswordField from "views/login/PasswordField";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

function CustomSmtp({ smtp, setSmtp, onSubmit }) {

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(updateSmtp, {
        onSuccess: () => {
            snack.success("Organization Profile Updated");
            queryClient.invalidateQueries("organization");
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "service") {
            setSmtp({
                "pass": "",
                "user": "",
                "host": "",
                "service": value,
                "port": "",
                "name": ""
            })
        } else {
            setSmtp((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    
    return (
        <Grid container spacing={2} mt={1} >
            <Grid item xs={2}>
                <Typography variant="body1">Service *</Typography>
            </Grid>
            <Grid item xs={8} display={"flex"}>
                <TextField
                    select
                    value={smtp?.service || ''}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    name="service"
                >
                    <MenuItem value="Gmail">G-mail</MenuItem>
                    <MenuItem value="yahoo">Yahoo</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={2}>
                {smtp?.service && <a href={smtp?.service === "outlook" ? "https://drive.google.com/file/d/1HpoeceKEiqeAuuK74Qdxp2t2lgfx3d9r/view?usp=sharing" : smtp?.service === "Gmail" ? "https://drive.google.com/file/d/1VuV3TKddc9MVtvwGbvNgveUIu6uwwZ5D/view?usp=sharing" : smtp?.service === "yahoo" ? "https://drive.google.com/file/d/1hvY_anHYXPI75UtpmKNrPBrdQu_0F82o/view?usp=sharing" : ""} target="_blank" style={{ margin: "6px" }}><Button variant="contained" size="small" sx={{ textAlign: "center", backgroundColor: smtp?.service === "outlook" ? "#0078d4" : smtp?.service === "Gmail" ? "#EA4235" : "#5E01D0", color: "#ffffff" }}><OndemandVideoIcon fontSize="small" />&nbsp;&nbsp;Integration Tutorial</Button></a>}
            </Grid>
            {smtp.service !== undefined && smtp.service !== "" && smtp.service !== null && <>
                <Grid item xs={2}>
                    <Typography variant="body1">From Name *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <TextField
                        value={smtp?.name}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                        name="name"
                    />
                </Grid></>}
            {smtp?.service === "Gmail" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">From Gmail *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <TextField
                        value={smtp?.user}
                        onChange={handleChange}
                        fullWidth
                        type="email"
                        size="small"
                        name="user"
                    />
                </Grid>
            </>}
            {smtp?.service === "outlook" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">Outlook Mail *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <TextField
                        value={smtp?.user}
                        onChange={handleChange}
                        fullWidth
                        type="email"
                        size="small"
                        name="user"
                    />
                </Grid>
            </>}
            {smtp?.service === "yahoo" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">Yahoo Mail *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <TextField
                        value={smtp?.user}
                        onChange={handleChange}
                        fullWidth
                        type="email"
                        size="small"
                        name="user"
                    />
                </Grid>
            </>}
            {smtp?.service === "outlook" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">App Password *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <PasswordField
                        required
                        value={smtp?.pass}
                        name="pass"
                        onChange={handleChange}
                        label={""}
                    />
                </Grid>
            </>}
            {smtp?.service === "Gmail" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">SMTP Password *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <PasswordField
                        required
                        value={smtp?.pass}
                        name="pass"
                        onChange={handleChange}
                        label={""}
                    />
                </Grid>
            </>}
            {smtp?.service === "yahoo" && <>
                <Grid item xs={2}>
                    <Typography variant="body1">App Password *</Typography>
                </Grid>
                <Grid item xs={8} sx={{ marginRight: "10px" }}>
                    <PasswordField
                        required
                        value={smtp?.pass}
                        name="pass"
                        onChange={handleChange}
                        label={""}
                    />
                </Grid>
            </>}
            {smtp.service !== undefined && smtp.service !== "" && smtp.service !== null && <>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={onSubmit}
                        disabled={isLoading}
                        size="large"
                        color="secondary"
                        variant="contained"
                    >
                        Update
                    </Button>
                </Grid>
            </>
            }
        </Grid>
    );
}

export default CustomSmtp;